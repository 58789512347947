import dayjs from "dayjs";
import { getFormattedPercentage } from "~/mixins/formatting";

dayjs.locale("de");
export function calculatePercentageDifference(
  current: number,
  reference: number,
): string {
  return getFormattedPercentage(current, reference);
}

export function getDirection(
  diff: number,
  positiveWord: string,
  negativeWord: string,
): string {
  return diff > 0 ? positiveWord : negativeWord;
}

export function calculateAbsoluteDifference(
  current: number,
  reference: number,
): number {
  return current - reference;
}

export function formatNumberToString(value: number): string {
  return value.toFixed(1).replace(".", ",");
}

export function formatPercentageWithoutZeros(
  value: number,
  includeParentheses = true,
  includeSign = true,
): string {
  if (Math.abs(value) < 0.1) return "";

  let formattedValue = Math.abs(value).toFixed(1).replace(".", ",");

  if (includeSign) {
    formattedValue = (value > 0 ? "+" : "-") + formattedValue;
  }

  formattedValue += "%";

  return includeParentheses ? ` (${formattedValue})` : formattedValue;
}

export function formatHeatingDays(value: number): string {
  return Math.round(value).toString();
}

export function currentMonth() {
  const currentMonth = dayjs().format("MMMM");
  return currentMonth;
}

export function getPreviousYear() {
  const currentYear = dayjs().subtract(1, "year").format("YYYY");
  return currentYear;
}
